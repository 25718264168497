import Icon from "@components/Ui/Icon";
import { twMerge } from "tailwind-merge";

type Props = {
  disabled?: boolean;
  className?: string;
};

export default ({ disabled, className = "" }: Props) => {
  return (
    <div className="relative inline-block leading-[0]">
      <Icon
        className={twMerge("text-purple-900/60 dark:text-[#201a41]", className)}
        name="spinner-circle"
      />
      {!disabled && (
        <Icon
          className={twMerge(
            "absolute inset-0 animate-spin text-primary",
            className,
          )}
          name="spinner-inner"
        />
      )}
    </div>
  );
};
