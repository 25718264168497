import { useTranslation } from "react-i18next";
import Icon from "@components/Ui/Icon";
import LogoutButton from "@components/LogoutButton";
import VendorCardList from "@components/VendorSelect/VendorCardList";
import { useEffect, useState } from "react";
import Image from "next/legacy/image";
import { twMerge } from "tailwind-merge";

const tailwindFadeIn = (isRendered: boolean) => [
  "inline-block transition-opacity delay-500 duration-[750ms] ease-in",
  !isRendered && "opacity-0",
  isRendered && "opacity-1 translate-y-0",
];

export default () => {
  const { t } = useTranslation();
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsRendered(true);
    });
    return () => setIsRendered(false);
  }, []);

  return (
    <div className="min-h-full w-full overflow-hidden bg-[url('https://assets.sesamy.com/images/login-bg.jpg')] bg-cover bg-center text-sm row sm:bg-fixed sm:bg-left-top">
      <div className="w-[calc(100%-theme(space.2)-theme(space.2))] max-w-[1295px] !flex-nowrap row-up-left sm:w-[calc(100%-theme(space.16)-theme(space.16))] sm:pt-10">
        <div className="column-left">
          <div
            className={twMerge(
              "w-full pb-4 pt-6 text-center sm:text-left",
              tailwindFadeIn(isRendered),
            )}
          >
            <Icon className="text-2xl md:text-2xl" name="portal" />
          </div>
          <div className="relative flex min-h-[calc(100vh-148px)] w-full flex-col rounded-2xl bg-gray-800 px-5 py-10 text-white sm:min-h-[700px] sm:max-w-md sm:px-14 sm:py-14 md:min-w-[448px] short:min-h-[558px]">
            <div
              className={twMerge(
                "flex flex-1 flex-col",
                tailwindFadeIn(isRendered),
              )}
            >
              <div className="mb-4 text-lg font-medium sm:text-2xl">
                {t("select_your_vendor")}
              </div>
              <div className="mb-8 text-gray-300">{t("vendor_access")}</div>
              <div className="mb-8 flex flex-1 flex-col justify-center">
                <VendorCardList />
              </div>

              <LogoutButton className="w-full" text={t("use_another")} />
            </div>
          </div>
          <div className="flex w-full items-center justify-center px-6 pb-8 pt-4 sm:justify-start">
            <div
              className={twMerge(
                "flex justify-center space-x-2 text-xs text-white sm:justify-normal md:text-xs",
                tailwindFadeIn(isRendered),
              )}
            >
              <button
                data-intercom-custom-launcher
                className="text-xs text-white hover:text-gray-200 md:text-xs"
              >
                {t("contact_support")}
              </button>
              <span className="text-gray-300">|</span>{" "}
              <span>{t("copyright_sesamy")}</span>
            </div>
          </div>
        </div>
        <div className="hidden flex-1 py-32 pl-8 sm:column-up-left portrait:hidden">
          <div className="relative text-4xl font-medium">
            <span
              className={twMerge(
                "w-[calc(100%+theme(space.2))] sm:w-[calc(100%+theme(space.16))]",
                tailwindFadeIn(isRendered),
              )}
            >
              {t("follow_real_time")}
            </span>
            <br />
            <span
              className={twMerge(
                "inline-block w-[calc(100%+theme(space.2))] text-gray-300 transition-opacity delay-500 duration-[750ms] ease-in sm:w-[calc(100%+theme(space.16))]",
                !isRendered && "opacity-0",
                isRendered && "opacity-1",
              )}
            >
              {t("more_revenue")}
            </span>
            <div
              className={twMerge(
                "absolute -left-6 right-0 top-[calc(theme(space.14)+100%-theme(space.1))] w-[720px] transition-[transform,opacity] delay-500 duration-[750ms] md:-left-7 md:top-[calc(theme(space.20)+100%-theme(space.1))] md:w-[920px]",
                !isRendered && "translate-y-[50%] opacity-0",
                isRendered && "opacity-1",
              )}
              data-testid="dashboard-video"
            >
              <Image
                className="h-auto w-full"
                src="/dashboard-tablet-frame.png"
                alt="a tablet frame with graphics within it"
                priority
                width={920}
                height={698}
              />
              <div className="absolute left-[6.1%] right-[6.1%] top-[6%]">
                <div className="relative pb-[75%]">
                  <Image
                    className="absolute left-0 top-0 h-full w-full border-0"
                    src="/dashboard-splash-image.png"
                    alt="tablet graphics showing Sesamy Portal dashboard"
                    priority
                    width={808}
                    height={600}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
