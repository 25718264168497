const IMG_S3BUCKET = process.env.NEXT_PUBLIC_IMAGE_S3BUCKET;
const IMG_PROXY = process.env.NEXT_PUBLIC_IMAGE_PROXY;

const FALLBACK_IMAGE =
  "https://assets.sesamy.com/images/placeholders/placeholder2_dark.png";

export const getImgProxyUrl = (imgPath: string | null, props?: string[]) => {
  let path = imgPath || FALLBACK_IMAGE;
  if (path.includes("images.sesamy.") && IMG_S3BUCKET) {
    path = path.replace(/https:\/\/images.sesamy.(?:dev|com)/i, IMG_S3BUCKET);
  }

  // Need to replace some chars to make it URL-safe
  const base64 = Buffer.from(path)
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return `${IMG_PROXY}/unsafe/${props ? props.join("/") + "/" : ""}${base64}`;
};
