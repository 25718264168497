import { useEffect } from "react";
import Head from "next/head";
import { useVendorContext } from "@contexts/vendor-context";
import { useRouter } from "next/router";
import FullscreenVendorSelect from "@components/VendorSelect/FullscreenVendorSelect";

export default () => {
  const { vendors } = useVendorContext();
  const router = useRouter();

  const hasMultipleVendors = !!vendors && vendors.length > 1;
  const hasSingleVendor = !!vendors && vendors.length === 1;

  useEffect(() => {
    if (hasSingleVendor) {
      router.push(`/${vendors![0]}/analytics`);
    }
  }, [router, vendors, hasSingleVendor]);

  return (
    <>
      <Head>
        <title>Sesamy Portal - Select vendor</title>
      </Head>
      {hasMultipleVendors && <FullscreenVendorSelect />}
    </>
  );
};
